import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivationEnd, NavigationError, Router } from '@angular/router';

import { BusyService } from 'src/modules/core/services/busy.service';
import { DesktopService } from 'src/modules/core/services/desktop.service';
import { SessionService } from 'src/modules/core/services/session.service';
import { CORE_ROUTES } from 'src/modules/core/core-router.module';
import { ClientConfiguration } from 'src/modules/core/model/client-configuraton';
import { CoreService } from 'src/modules/core/services/core.service';
import { User } from 'src/modules/core/model/user';
import { MatDrawerMode } from '@angular/material/sidenav';
import { NavigationService } from 'src/modules/core/services/navigation.service';
import { CORE_ROUTE_PATH } from 'src/modules/core/core.definition';
import { TelemetryService } from 'src/modules/core/services/telemetry.service';
import { EAppInsightsSeverityLevel } from 'src/modules/core/model/application-insights';


@Component({
   selector: 'asc-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
   standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
   public environment?: string;
   public title?: string;
   public version?: string;
   public isProduction!: boolean;
   public isSettingsSubRouteActive: boolean = false;
   private subscription = new Subscription();
   private clientConfiguration: ClientConfiguration;

   constructor(
      private telemetryService: TelemetryService,
      private busyService: BusyService,
      private desktopService: DesktopService,
      private navigationService: NavigationService,
      private sessionService: SessionService,
      private router: Router,
      coreService: CoreService
   ) {
      this.clientConfiguration = coreService.clientConfiguration;
   }

   /**
    * Initialize session.
    * a) Authenticate user
    * b) Load modules
    * c) Redirect to url (deep-link support)
    */
   async ngOnInit(): Promise<void> {
      this.environment = this.clientConfiguration.environment;
      this.title = this.clientConfiguration.title;
      this.version = this.clientConfiguration.version;
      this.isProduction = this.clientConfiguration.production;

      // Handle routing errors
      this.subscription.add(
         this.router.events.subscribe((value: any) => {
            if (value instanceof ActivationEnd) {
               // Change menu button for small devices
               const snapshot = value.snapshot;
               this.isSettingsSubRouteActive = (snapshot.routeConfig!.path === CORE_ROUTE_PATH.SETTINGS) && (snapshot.children.length > 0);
            }

            if (value instanceof NavigationError) {
               // Hide loading indicator
               this.busyService.clearBusy();

               // Re-try navigation when user is logged-in
               if (!this.sessionService.isAuthenticated) {
                  this.sessionService.loginAsync(value.url);
               }
            }
         })
      );

      try {
         this.busyService.setBusy();

         const redirectUrl = await this.sessionService.initializeModulesAsync(this.router, CORE_ROUTES)
         this.telemetryService.logEvent('Session.Started');

         const url = redirectUrl || window.location.pathname;
         await this.router.navigateByUrl(url);

         this.busyService.clearBusy();
      }
      catch (error) {
         this.busyService.clearBusy();
         console.error(error);
         this.telemetryService.logException(error as Error, undefined, EAppInsightsSeverityLevel.Error, { appSource: AppComponent.name });
      }
   }

   ngOnDestroy() {
      this.subscription.unsubscribe();
   }

   // -------------------------------------------------------- Desktop handling

   public get isEmbedded(): boolean {
      return this.desktopService.isEmbedded;
   }

   public get isDesktopLayout(): boolean {
      return this.desktopService.isDesktopLayout;
   }

   public get isMobileLayout(): boolean {
      return this.desktopService.isMobileLayout;
   }

   public get canShowMenuButton(): boolean {
      return this.desktopService.canShowMenuButton && (this.desktopService.canShowSettingsPanel || !this.isSettingsSubRouteActive);
   }

   public get isMenuOpen(): boolean {
      return this.desktopService.isMenuOpen;
   }

   public set isMenuOpen(value: boolean) {
      this.desktopService.isMenuOpen = value;
   }

   public get menuMode(): MatDrawerMode {
      return this.desktopService.isDesktopLayout ? 'side' : 'over';
   }

   public openMenu() {
      this.desktopService.openMenu();
   }

   public get canShowSettingsPanel(): boolean {
      return this.desktopService.canShowSettingsPanel;
   }

   // -------------------------------------------------------- Session handling

   public get isAuthenticated(): boolean {
      return this.sessionService.isAuthenticated;
   }

   public get user(): User {
      return this.sessionService.user;
   }

   public login() {
      this.sessionService.loginAsync();
   }

   public logout() {
      this.sessionService.logoutAsync();
   }

   // ----------------------------------------------------------- Busy handling

   public get isBusy(): boolean {
      return this.busyService.isBusy;
   }

   public get busyMessage(): string | undefined {
      return this.busyService.message;
   }

   public menuItemSelected() {
      this.desktopService.closeMenu();
   }

   // ------------------------------------------------------- Settings handling

   public get isSettingsPanelVisible(): boolean {
      return this.isSettingsSubRouteActive;
   }

   public navigateToSettings() {
      this.navigationService.navigateToSettings();
   }
}
